<template>
  <div class="footer">
    <div class="f-con">
      <div class="left-info">
        <p>
          <img src="../../assets/icon_map.png" />
          <span class="front">地址：{{ company.address }} </span>
        </p>
        <p>
          <img src="../../assets/icon_email.png" />
          <span>E-mail：{{ company.email }}</span>
        </p>
        <p>ICP/EDI许可证：津B2-20250062</p>
        <!-- <p>ICP许可证：津B2-20250062</p> -->
      </div>
      <div class="copy-right">
        Copyright ©{{ copyRight }}
        <p>{{ support }}</p>
        <!-- <p>ICP许可证：津ICP备2023002843号-1</p> -->
       <p>ICP备案号：津ICP备2023002843号-1</p>
      </div>
      <div class="right-code">
        <div class="code">
          <img :src="imgSrc" alt="" />
          <img :src="imgSrc1" alt="" />
        </div>
        <p>扫码关注，了解更多</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      imgSrc: window.pageConfiguration.company.corporateQRcode,
      imgSrc1: window.pageConfiguration.company.standbyQRcode,
      company: window.pageConfiguration.company,
      title: window.pageConfiguration.title,
      copyRight: window.pageConfiguration.company.copyright,
      support: window.pageConfiguration.company.support,
    };
  },
};
</script>

<style lang="less" scoped>
@local-color: #333638;
.footer {
  height: 210px;
  // height: 100%;
  width: 100%;
  background: @local-color;
  .f-con {
    width: 1472px;
    padding-top: 39px;
    margin: 0 auto;
    color: #ccc;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    .copy-right {
      margin-top: 20px;
      p {
        margin-top: 24px;
        display: flex;
        align-items: center;
      }
    }
    .left-info {
      text-align: left;
      .front {
        margin-right: 47px;
      }
      p {
        margin-bottom: 24px;
        display: flex;
        align-items: center;

        img {
          margin-right: 10px;
        }
      }
      p:last-child {
        margin-bottom: 0;
      }
      .phone {
        margin-right: 31px;
      }
    }
    .right-code {
      text-align: center;
      color: #fff;
      display: block;
      .code {
        height: 106px;
        margin: 0 auto;
        display: flex;
        img {
          width: 100%;
          height: 100%;
          margin: 0px 10px;
        }
      }
      p {
        margin-top: 18px;
      }
    }
  }
}
</style>
