import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import wx from 'weixin-js-sdk';
Vue.prototype.wx = wx;
import ElementUI from 'element-ui';
import Vant from 'vant';
import 'vant/lib/index.css';
Vue.use(Vant);
import device from "vue-device-detector" //引入vue-device-detector 插件 检测设备类型
Vue.use(device)

import 'element-ui/lib/theme-chalk/index.css';
import basicContainer from './components/basic-container/main'//引入全局容器
import store from "./store/index";
import moment from 'moment'//导入文件
import VueAwesomeSwiper from 'vue-awesome-swiper'
import './assets/style/base.less'
import '../public/style/commen.less'
import '../public/style/theme/index.css'
import { getStore } from './js/utils/store'
import { message } from './js/utils/message'
import mavonEditor from 'mavon-editor'
import 'mavon-editor/dist/css/index.css'
import VueMeta from 'vue-meta';
import 'echarts/extension/bmap/bmap';



Vue.use(VueMeta);
Vue.use(mavonEditor);


// import style
import 'swiper/css/swiper.css'
// import 'swiper/swiper-bundle.css'
import JsonExcel from 'vue-json-excel'
Vue.component('downloadExcel', JsonExcel)
Vue.use(VueAwesomeSwiper, /* { default options with global component } */)
// 注册全局容器
Vue.component('basicContainer', basicContainer)

Vue.use(ElementUI);
Vue.config.productionTip = false;
Vue.prototype.$moment = moment;//赋值使用
Vue.prototype.$message = message;
Vue.prototype.$store = store;
// VueRouter对象可以做处理
router.beforeEach((to, from, next) => {
  // chrome
  document.body.scrollTop = 0
  // firefox
  document.documentElement.scrollTop = 0
  let token = getStore({ name: 'access_token' })
  let oldToken = sessionStorage.getItem('oldToken')
  if (token && oldToken && token != oldToken) {
    sessionStorage.setItem('oldToken', token)
    window.location.reload()
  }
  if (to.matched.length === 0) {  // 如果未匹配到路由
    router.push({ path: '/home/homepage' });
  } else {
    let token = getStore({ name: 'access_token' });
    let identityObj = getStore({ name: 'identityObj' });
    if (to.meta.Author) {
      if (token && identityObj.identityTypeCode) {
        next();
      } else {
        router.push({ path: '/home/login' });
      }
      next();
    } else {
      if (to.path == '/home/login' || to.path == '/home/register') {
        if (token && identityObj.identityTypeCode) {
          router.push({ path: '/home/homepage' });
        } else {
          next();
          // next({ ...to, replace: true });
        }
      }
      next();
    }
  }
});
//配合全局message提示语高度
import { Message } from 'element-ui';
//定义一个新的Message方法，多传入一个offset参数
const $message = options => {
  return Message({
    ...options,
    offset: 85
  });
};

//重写方法,将offset写入options
['success', 'warning', 'info', 'error'].forEach(type => {
  $message[type] = options => {
    if (typeof options === 'string') {
      options = {
        message: options,
        offset: 85
      };
    }
    options.type = type;
    return Message(options);
  };
});
//将$message挂载到this上
Vue.prototype.$message = $message;
//不加这行代码运行this.$message.closeAll时会报错
Vue.prototype.$message.closeAll = Message.closeAll;
// 自定义指令来实现只能输入整数的验证
// Vue.directive('integer', {
//   bind(el, binding, vnode) {
//     el.addEventListener('input', () => {
//       let value = el.value;
//       value = value.replace(/[1-9]\d*$//g, ''); // 只允许数字
//       if (value !== el.value) {
//         el.value = value;
//       }
//     });
//   }
// });

export default new Vue


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
